import { useState } from 'react';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createTransaction, updateTransaction } from '../graphql/mutations';
import { CreateTransactionMutation, Estate, UpdateTransactionInput, Transaction, UpdateTransactionMutation} from '../API';
import { GraphQLResult } from '@aws-amplify/api';
import { CATEGORIES } from '../category';


export interface TransactionFormProps {
  estates: Estate[],
  initTransaction: Transaction,
  homeSwitch: (name: string, params: any[]) => void,
  cancelHandler: () => void
}

const TransactionForm = ({ initTransaction, estates, homeSwitch, cancelHandler }: TransactionFormProps) => {

  const [formState, setFormState] = useState(initTransaction.id? {
      estateIndex: estates.findIndex(e => e.id === initTransaction.estateID),
      categoryIndex: CATEGORIES.findIndex(c => c.name === initTransaction.category),
      amount: initTransaction.amount,
      date: new Date(initTransaction.date.slice(0,10)),
      description: initTransaction.description
    } :
    {
      estateIndex: estates.findIndex(e => e.id === initTransaction.estateID),
      categoryIndex: 2,
      amount: 0,
      date: new Date(),
      description: ''
    }
  );

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<string[]>(initTransaction?.fileKey? 
    JSON.parse(initTransaction.fileKey) : []);
  const [tempFileList] = useState<string[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDeleteFileIndex, setToDeleteFileIndex] = useState(1000);

  const setInput = (key: string)=> (event: any) => {
    setFormState({ ...formState, [key]: event.target.value });
  }

  const saveClickHandler = async () => {
    if (formState.amount < 0) {
      homeSwitch('ErrorNotification',['Amount must be larger than 0!']);
      return;
    }

    const transaction: Transaction = { 
      estateID: estates[formState.estateIndex].id?? '',
      category: CATEGORIES[formState.categoryIndex].name,
      isIncome: CATEGORIES[formState.categoryIndex].isIncome,
      amount: formState.amount,
      date: (new Date(formState.date.getTime() + 10*3600*1000)).toISOString().slice(0, 10) + '+10:00',
      description: (!formState.description || formState.description.length === 0) ? 
        undefined : formState.description,
      fileKey: fileList.length > 0 ? JSON.stringify(fileList) as string : undefined
    };

    try {
      if (initTransaction.id){ 
      // Update transaction and return to home.
        let transactionInput: UpdateTransactionInput = {
          ...transaction,
          id: initTransaction.id?? '',
        };

        if (initTransaction.fileKey && fileList.length === 0) {
          transactionInput.fileKey = '[]';
        }

        let result = await API.graphql(
          graphqlOperation(
            updateTransaction, 
            { input: transactionInput }
          )
        ) as GraphQLResult<UpdateTransactionMutation>;

        let updatedTransaction = result.data?.updateTransaction as Transaction;

        homeSwitch('SuccessNotification', ['Transaction has been updated successfully!']);
        homeSwitch('TransactionUpdated', [ updatedTransaction ]);

      } else {
      // Add new transaction and return the new transaction to list.
        let result = await API.graphql(
          graphqlOperation(
            createTransaction, 
            { input: transaction }
          )
        ) as GraphQLResult<CreateTransactionMutation>;

        let newTransaction = result.data?.createTransaction as Transaction;

        homeSwitch('SuccessNotification', ['New transaction has been added successfully!']);
        homeSwitch('NewTransactionAdded', [newTransaction]);
        setFormState({
          estateIndex: estates.findIndex(e => e.id === initTransaction.estateID),
          categoryIndex: 2,
          amount: 0,
          date: new Date(),
          description: ''
        });
        setFileList([]);
      }

    } catch (err) {
      console.log(err);
      homeSwitch('ErrorNotification', [`Failed to add the new transaction. ${err}`]);
    }
  }

  const dateChangedHandler = (date: Date | null) => {
    if (date)
    setFormState({
      ...formState,
      date: date
    })
  }

  const uploadFile = () => {
    try {
      var input = document.createElement('input');
      input.type = 'file';
  
      input.addEventListener("change", async () => {
        if (input.files){
          let file = input.files[0];

          if (file.size > 5 * 1024 * 1024) {
            homeSwitch('ErrorNotification',['Too large, the size should be less than 5 MB.']);
            return;
          }

          // Check if the file name exist.
          let year = new Date().getFullYear();
          let fileKey = `${initTransaction.estateID}/${year}/${file.name}`;
          let exist = false;

          let listResult = await Storage.list(`${initTransaction.estateID}/${year}/`, { level: 'private' });

          listResult.forEach(item => {
            if (item.key === fileKey) {
              homeSwitch('ErrorNotification',[`File "${file.name}" already exists, please change a name.`]);
              exist = true;
            }
          });

          if (exist) return;
          // Upload file
          setUploading(true);
          const putResult = await Storage.put(fileKey, file, {
            level: "private",
            contentType: file.type,
          });
          setUploading(false);

          let newList = [...fileList, putResult.key];
          setFileList(newList);
          tempFileList.push(putResult.key);
        }
      }, false)  

      input.click();

    } catch (err) {
      console.log(err);
    }
  }

  const cancelClickHandler = async () => {
    if (!initTransaction.id) {
      cancelHandler();
      return;
    }
    // Delete uploaded files for existing transaction.
    tempFileList.forEach(file => {
      Storage.remove(file, { level: 'private' });
    });

    // Check if any files have been deleted.
    // If deleted, update the transaction fileKey.
    if (initTransaction.id && initTransaction.fileKey) {
      let fileDeleted = false;
      let files: string[] = JSON.parse(initTransaction.fileKey);
      files.forEach(file => {
        if (!fileList.includes(file)) fileDeleted = true;
      });

      if (fileDeleted) {
        let transactionInput: UpdateTransactionInput = {
          estateID: initTransaction.estateID,
          category: initTransaction.category,
          isIncome: initTransaction.isIncome,
          description: initTransaction.description,
          amount: initTransaction.amount,
          date: initTransaction.date,
          id: initTransaction.id?? '',
          fileKey: fileList.length > 0 ? JSON.stringify(fileList) as string : '[]',
        };

        let result = await API.graphql(
          graphqlOperation(
            updateTransaction, 
            { input: transactionInput }
          )
        ) as GraphQLResult<UpdateTransactionMutation>;

        let updatedTransaction = result.data?.updateTransaction as Transaction;

        homeSwitch('TransactionUpdated', [ updatedTransaction ]);
      }
    }

    cancelHandler();
  }

  const fileDownloadClickedHandler = async (fileKey: string) => {
    try {
      const result = await Storage.get(fileKey, { level: 'private', download: true });
      downloadBlob(result.Body as Blob, fileKey.substring(fileKey.lastIndexOf('/') + 1));
    } catch (err) {
      homeSwitch('ErrorNotification', [`Something wrong with the file. ${err}`]);
    }
  }

  const fileDeleteClickedHandler = (index: number) => {
    setToDeleteFileIndex(index);
    setConfirmOpen(true);
  }

  const deleteConfirmHandler = () => {
    Storage.remove(fileList[toDeleteFileIndex], { level: 'private' });
    let newList = fileList.slice(0, toDeleteFileIndex);
    newList = newList.concat(fileList.slice(toDeleteFileIndex + 1, fileList.length));
    setFileList(newList);
    setConfirmOpen(false);
  }


  return (
    <>
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel>Estate</InputLabel>
              <Select
                value={formState.estateIndex}
                onChange={setInput('estateIndex')}
                disabled={true}
              >
                {estates.map((estate, index)=>(
                  <MenuItem key={index} value={index}>{estate.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel >Category</InputLabel>
              <Select
                value={formState.categoryIndex}
                onChange={setInput('categoryIndex')}
                label="Category"
              >
                {CATEGORIES.map((category, index)=>(
                  <MenuItem key={index} value={index}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Amount</InputLabel>
              <Input
                type="number"
                value={formState.amount}
                onChange={setInput('amount')}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Date"
                value={formState.date}
                inputFormat="yyyy-MM-dd"
                onChange={(newValue) => {
                  dateChangedHandler(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth  sx={{ m: 1 }} variant="standard"/>}
              />
            </LocalizationProvider>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel >Description</InputLabel>
              <Input
                value={formState.description}
                multiline
                onChange={setInput('description')}
              />
            </FormControl>
              <LoadingButton 
                loading={uploading}
                loadingPosition="start"
                startIcon={<AddIcon />}
                onClick={uploadFile}
              >
                 Attache Files
              </LoadingButton>
          </CardContent>
          <List dense={true}>
            {fileList.map((fileKey, index)=>{
              return(
                <ListItem
                  key={fileKey}
                  secondaryAction={
                    <>
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={()=>fileDownloadClickedHandler(fileKey)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        aria-label="delete"
                        onClick={()=>fileDeleteClickedHandler(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ width: 24, height: 24 }}>
                      <AttachFileIcon fontSize="small"/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={fileKey.substring(fileKey.indexOf('/') + 1)}
                    // secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              )
            })}
          </List>
          <CardActions>
            <Button 
              // variant="contained" 
              style={rightAlignItem}
              onClick={saveClickHandler}
            >
              Save
            </Button>
            <Button 
              // variant="contained" 
              color="error"
              onClick={cancelClickHandler}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
        <Modal
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 id="modal-title">Please confirm</h2>
            <p id="modal-description">
              The file will be deleted permanently from cloud, are you sure to delete it?
            </p>
            <Button onClick={deleteConfirmHandler}>Yes</Button>
            <Button onClick={()=>setConfirmOpen(false)}>No</Button>
          </Box>
        </Modal>
      </Container>
    </>
  )
}

function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

const rightAlignItem = {
  marginLeft: "auto"
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default TransactionForm
