import { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';

import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createEstate, updateEstate } from '../graphql/mutations';
import { CreateEstateMutation, Estate, UpdateEstateInput } from '../API';
import { GraphQLResult } from '@aws-amplify/api';


export interface EstateFormProps {
  initEstate: Estate;
  image: string | undefined;
  homeSwitch: (name: string, params: any[]) => void;
}

const EstateForm = ({ initEstate, image, homeSwitch }: EstateFormProps) => {

  const defaultImageSrc = `${process.env.PUBLIC_URL}/images/defaultImage.png`;

  const [formState, setFormState] = useState(initEstate);
  const [imageSrc, setImageSrc] = useState(image?? defaultImageSrc);

  const setInput = (key: string)=> (event: any) => {
    setFormState({ ...formState, [key]: event.target.value });
  }

  const dataURLToBlob = (dataURL: string) => {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
  }

  // store the temporary keys to be deleted.
  const [tempImageKeys] = useState<string[]>([]);

  const uploadImage = () => {
    try {
      var input = document.createElement('input');
      input.type = 'file';
      input.accept = '.jpg,.png,.gif,.jpeg';
  
      input.addEventListener("change", () => {
        if (input.files){
          let file = input.files[0];

          let reader = new FileReader();

          reader.onload = (readerEvent) => {
            let img = document.createElement('img');

            // Resize image.
            img.onload = async () => {
              let canvas = document.createElement('canvas'), 
                max_size = 450,
                width = img.width,
                height = img.height;

              if (width > height) {
                if (width > max_size) {
                  height *= max_size / width;
                  width = max_size;
                }
              } else {
                if (height > max_size) {
                  width *= max_size / height;
                  height = max_size;
                }
              }

              canvas.width = width;
              canvas.height = height;
              canvas.getContext('2d')?.drawImage(img, 0, 0, width, height);

              // Get resized image file blob
              let dataUrl = canvas.toDataURL(file.type);
              let resizedImage = dataURLToBlob(dataUrl);

              // Upload image
              const timeStamp = Date.now();
              const result = await Storage.put(`Estate_${timeStamp}`, resizedImage, {
                level: "private",
                contentType: resizedImage.type,
              });
              
              // Record the image key and show the image
              setFormState({ ...formState, imageKey: result.key});
              setImageSrc(await Storage.get(result.key, { level: 'private'}));

              tempImageKeys.push(result.key);
            }

            img.src = readerEvent.target?.result as string?? '';
          }
          reader.readAsDataURL(file);
        }
      }, false)  

      input.click();

    } catch (err) {
      console.log(err);
    }
  }

  const saveClickHandler = async () => {
    const estate: Estate = { ...formState};

    // Remove already uploaded temporary image.
    if (tempImageKeys.length > 0) {
      tempImageKeys.pop();
      tempImageKeys.forEach(key => {
        Storage.remove(key, { level: 'private' });
      });
    }

    try {
      if (image){ 
      // Update estate and return to home.
        let estateInput: UpdateEstateInput = {
          id: estate.id?? '',
          name: estate.name,
          address: estate.address,
          imageKey: estate.imageKey
        };

        await API.graphql(graphqlOperation(updateEstate, { input: estateInput }));

        // Remove old image.
        if (estateInput.imageKey && estateInput.imageKey !== initEstate.imageKey) {
          Storage.remove(initEstate.imageKey, { level: 'private' });
          initEstate.imageKey = estateInput.imageKey;
        }

        homeSwitch('SuccessNotification', ['Estate has been updated successfully!']);

        setTimeout(() => homeSwitch('EstateUpdated', [ estate ]), 1000);
      } else {
      // Add new estate and return the new estate to home.
        let result = await API.graphql(graphqlOperation(createEstate, { input: estate })) as GraphQLResult<CreateEstateMutation>;
        let newEstate = result.data?.createEstate as Estate;

        homeSwitch('SuccessNotification', ['New estate has been added successfully!']);

        let newEstateImage: string = newEstate.imageKey === 'default'?
          defaultImageSrc :
          await Storage.get(estate.imageKey, { level: 'private'});

        setTimeout(() => homeSwitch('NewEstateAdded', [{
          'estate': newEstate, 
          'image': newEstateImage
        }]), 1000);
      }

    } catch (err) {
      console.log(err);
      homeSwitch('ErrorNotification', [`Failed to add the new estate. ${err}`]);
    }
  }

  const cancelClickHandler = () => {
    tempImageKeys.forEach(key => {
      Storage.remove(key, {level: 'private'});
    });

    homeSwitch('EstateFormCancelClicked', []);
  } 

  const rightAlignItem = {
    marginLeft: "auto"
  }

  const imageWrapper = {
    cursor: "pointer"
  }

  return (
    <>
      <Container maxWidth="sm">
        <Card>
          <Tooltip 
            title="Change the estate's photo" 
            placement="top" 
            followCursor 
            style={imageWrapper}
          >
            <CardMedia
              component="img"
              height="200"
              image={imageSrc}
              alt="my estate"
              onClick={uploadImage}
            />
          </Tooltip>
          <CardContent>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
              <Input
                id="standard-adornment-amount"
                value={formState.name}
                onChange={setInput('name')}
              />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
              <InputLabel htmlFor="standard-adornment-amount">Address</InputLabel>
              <Input
                id="standard-adornment-amount"
                value={formState.address}
                onChange={setInput('address')}
              />
            </FormControl>
          </CardContent>
          <CardActions>
            <Button 
              // variant="contained" 
              style={rightAlignItem}
              onClick={saveClickHandler}
            >
              Save
            </Button>
            <Button 
              // variant="contained" 
              color="error"
              onClick={cancelClickHandler}
            >
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Container>
    </>
  )
}

export default EstateForm
