import { useState } from 'react'

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FilterListIcon from '@mui/icons-material/FilterList';
import UploadIcon from '@mui/icons-material/Upload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';

import { Estate, Transaction } from '../../API';
import { importTransactions } from '../../transactions';

export type Filter = 'Income' | 'Expense' | 'All';

interface EnhancedTableToolbarProps {
  estate: Estate | Estate[],
  homeSwitch: (name: string, params: any[]) => void,
  filterList: (filter: Filter) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { estate, homeSwitch, filterList } = props;
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const filterClickedHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const filterSelectedHandler = (filter: Filter) => {
    filterList(filter);
    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const importTransactionsClicked = async () => {
    if (Array.isArray(estate)) return;
    importTransactions(estate.id?? '', progressHandler);
  }

  const progressHandler = (progress: number, data?: Transaction[]) => {
    if (progress == 0) {
      setUploading(true);
    } else if (progress < 100) {
      setProgress(progress);
    } else {
      setProgress(100);
      setTimeout(() => {
        setUploading(false);
        setProgress(0);
        homeSwitch('TransactionsImported',data??[]);
      }, 1000);
    }
  }

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Transactions
          </Typography>
          {!Array.isArray(estate) && <>
            <Tooltip title="Add new transaction">
              <IconButton onClick={()=>homeSwitch('AddNewTransactionClicked',[estate])}>
                <ReceiptIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Import transactions from csv file">
              <IconButton onClick={importTransactionsClicked}>
                <UploadIcon />
              </IconButton>
            </Tooltip>
          </>}
          <Tooltip title="Filter list">
            <IconButton
              id="filter-button"
              aria-controls={open ? 'filter-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={filterClickedHandler}
            >
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="filter-menu"
            aria-labelledby="filter-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={()=>filterSelectedHandler("Income")}>Income</MenuItem>
            <MenuItem onClick={()=>filterSelectedHandler("Expense")}>Expense</MenuItem>
            <MenuItem onClick={()=>filterSelectedHandler("All")}>All</MenuItem>
          </Menu>
      </Toolbar>
      {uploading && <LinearProgress variant="determinate" value={progress} />}
    </>

  )
}

export default EnhancedTableToolbar
