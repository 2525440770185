import { API, graphqlOperation, Storage } from "aws-amplify";
import { GraphQLResult } from '@aws-amplify/api';
import { CreateTransactionMutation, Transaction } from "./API";
import { createTransaction, deleteTransaction } from "./graphql/mutations";

async function importTransactions(estateID: string, progress: Function) {
  if (estateID.length === 0) return;

  try {
    var input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';

    input.addEventListener("change", async () => {
      if (input.files){
        let file = input.files[0];
        const reader = new FileReader();
        reader.onload = async function (event) {
          if (event.target) {
            progress(0);
            const text = event.target.result;
            const data = csvToArray(text as string) as Transaction[];

            const transactions = convertToTransactions(data, estateID);
            progress(10);

            const newTransactions = await uploadTransactions(transactions, progress);

            progress(100, newTransactions);
          }
        };
        reader.readAsText(file);
      }
    }, false)  

    input.click();

  } catch (err) {
    console.error(err);
  }
}

// delimiter ","
function csvToArray(str: string) {
  const lines = str.split('\n');
  const headers = lines[0].slice(1, -2).split('","');

  let objects: any[] = [];
  lines.forEach((row, i) => {
    if (i === 0) return;

    const o: any = {};
    row.slice(1, -2).split('","').forEach((v, j) => o[headers[j]] = v);

    objects.push(o);
  });

  return objects;
}

function convertToTransactions (data: Transaction[], estateID: string) {
  const transactions: Transaction[] = [];

  // From DynamoDB save csv
  for (const t of data) {
    transactions.push({ 
      estateID: estateID,
      category: t.category,
      isIncome: t.isIncome,
      amount: t.amount,
      date: t.date,
      description: (!t.description || t.description.length === 0) ? 
        undefined : t.description,
    })
  }

  return transactions;
}

async function uploadTransactions(transactions: Transaction[], progress: Function) {
  let newTransactions: Transaction[] = [];

  for (const [i, transaction] of transactions.entries()) {
    try {
      let result = await API.graphql(
        graphqlOperation(
          createTransaction, 
          { input: transaction }
        )
      ) as GraphQLResult<CreateTransactionMutation>;
  
      newTransactions.push(result.data?.createTransaction as Transaction);
  
      progress(10 + 100 * i / transactions.length);
    } catch (err) {
      console.error(err);
      return;
    }
  };

  return newTransactions;
}

async function deleteTransactionAndFile(transaction: Transaction) {
  try {
    if (transaction.fileKey) {
      const files: string[] = JSON.parse(transaction.fileKey);
      for (const file of files) {
        await Storage.remove(file, { level: 'private' });
      }
    }
    await API.graphql(
      graphqlOperation(
        deleteTransaction,
        {
          input: {
            id: transaction.id, 
          }
        }
      )
    );
    return { succeeded: true, message: "deleted" }
  } catch (err) {
    return { succeeded: false, message: err }
  }
}

export {importTransactions, deleteTransactionAndFile}