
export type Category = {
    name: string,
    isIncome: boolean
}

export const CATEGORIES: Category[] = [
    { name: 'Cost Base', isIncome: false },
    { name: 'Borrowing Base', isIncome: false },
    { name: 'Rental Income', isIncome: true },
    { name: 'Other Income', isIncome: true },
    { name: 'Advertising', isIncome: false },
    { name: 'Body Corporate', isIncome: false },
    { name: 'Borrowing Expense', isIncome: false },
    { name: 'Cleaning', isIncome: false },
    { name: 'Council Rate', isIncome: false },
    { name: 'Capital Allowance', isIncome: false },
    { name: 'Gardening', isIncome: false },
    { name: 'Insurance', isIncome: false },
    { name: 'Interest on Loans', isIncome: false },
    { name: 'Land Tax', isIncome: false },
    { name: 'Legal Fee', isIncome: false },
    { name: 'Pest Control', isIncome: false },
    { name: 'Agent Fee', isIncome: false },
    { name: 'Repair', isIncome: false },
    { name: 'Capital Works', isIncome: false },
    { name: 'Stationery Phone Postage', isIncome: false },
    { name: 'Travel Expense', isIncome: false },
    { name: 'Water Charge', isIncome: false },
    { name: 'Other Expense', isIncome: false },
    { name: 'Principal Payment', isIncome: false },
    { name: 'Depreciating Assets - 5 Years', isIncome: false },
    { name: 'Depreciating Assets - 7 Years', isIncome: false },

]