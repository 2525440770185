/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEstate = /* GraphQL */ `
  mutation CreateEstate(
    $input: CreateEstateInput!
    $condition: ModelEstateConditionInput
  ) {
    createEstate(input: $input, condition: $condition) {
      id
      name
      address
      imageKey
      Transactions {
        items {
          id
          date
          amount
          description
          category
          isIncome
          fileKey
          estateID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEstate = /* GraphQL */ `
  mutation UpdateEstate(
    $input: UpdateEstateInput!
    $condition: ModelEstateConditionInput
  ) {
    updateEstate(input: $input, condition: $condition) {
      id
      name
      address
      imageKey
      Transactions {
        items {
          id
          date
          amount
          description
          category
          isIncome
          fileKey
          estateID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEstate = /* GraphQL */ `
  mutation DeleteEstate(
    $input: DeleteEstateInput!
    $condition: ModelEstateConditionInput
  ) {
    deleteEstate(input: $input, condition: $condition) {
      id
      name
      address
      imageKey
      Transactions {
        items {
          id
          date
          amount
          description
          category
          isIncome
          fileKey
          estateID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      date
      amount
      description
      category
      isIncome
      fileKey
      estateID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      date
      amount
      description
      category
      isIncome
      fileKey
      estateID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      date
      amount
      description
      category
      isIncome
      fileKey
      estateID
      createdAt
      updatedAt
      owner
    }
  }
`;
