import { useEffect, useState } from 'react'
import Highcharts from "highcharts/highstock";
import BarChart from "highcharts-react-official";
import Paper from '@mui/material/Paper';

import { Transaction } from '../API';

interface BarChartProps {
  transactions: Transaction[],
  dateRange: string[]
}

type BarData = {
  name: string,
  data: number[]
}

function TransactionsBarChart({transactions, dateRange}: BarChartProps) {
  const [data, setData] = useState<BarData[]>();
  const [title, setTitle] = useState('');

  useEffect(() => {
    makeData();
  }, [transactions])

  const makeData = () => {

    let categorySum = new Map();

    transactions.forEach(transaction => {
      let category = transaction.category;
      let i: string = (transaction.isIncome? 1 : 0).toString();
      let name = `${i}${category}`;
      if (categorySum.get(name)) {
        categorySum.set(name, categorySum.get(name) + transaction.amount);
      } else {
        categorySum.set(name, transaction.amount);
      }
    });

    let tempData = [] as BarData[];
    let income = 0, cost = 0;

    categorySum.forEach((value: number, key: string) => {
      let i = Number(key.slice(0, 1));
      if (i === 0) cost += value;
      else income += value;

      let name = key.substring(1);
      let data = [0, 0];
      data[i] = value;
      tempData.push({
        name: name,
        data: data
      })
    });

    setData(tempData);

    let net = (income - cost)
      .toLocaleString('en-US', { 
        style: 'currency', 
        currency: 'USD'
      }); 
    
    setTitle(`${dateRange[0].slice(0,10)} - ${dateRange[1].slice(0,10)} Net: ${net}`);

  }

  let options = {
    chart: {
      type: 'bar',
      // backgroundColor: blockColor()
      style: {
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
      }
    },
    credits: {
          enabled: false
      },
    title: {
        text: title,
        style: {
          color: 'rgb(158, 161, 176)'
        }
    },
    legend: {
      itemStyle: {
        color: 'rgb(158, 161, 176)'
      },
      itemHoverStyle: {
        // color: hoverColor()
      }
    },
    xAxis: {
        categories: ['Cost', 'Income'],
        labels: {
        style: {
          color: 'rgb(158, 161, 176)',
       }
    },
    },
    yAxis: {
      gridLineColor: 'rgb(158, 161, 176)',
      gridLineDashStyle: 'longdash',
      min: 0,
      title: {
          text: ''
      },
      labels: {
      //   formatter: function() {
      //     return (this.value) + " %";
      //   }
      }
    },
    tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>${point.y:,.2f}</b> ({point.percentage:.1f}%)<br/>',
        shared: true
    },
    plotOptions: {
        bar: {
            stacking: 'value'
        },
        series: {
          borderWidth: 0,
        }
    },
    series: data
  }

  return (
    <Paper>
      <BarChart highcharts={Highcharts} options={options} />
    </Paper>
  )
}

export default TransactionsBarChart
