import { useEffect, useState } from 'react'
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import Paper from '@mui/material/Paper';

import { Transaction } from '../API';

interface CostPieChartProps {
  transactions: Transaction[]
}
type PieData = {
  name: string,
  y: number
}

function CostPieChart({ transactions }: CostPieChartProps) {

  const [data, setData] = useState<PieData[]>();

  useEffect(() => {
    makeData();
  }, [transactions])

  let options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      // backgroundColor: blockColor()
      style: {
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
      }
    },
    credits: {
      enabled: false
    },
    legend: {
      // align: 'right',
      // verticalAlign: 'middle',
      // layout: 'vertical',
      itemStyle: {
        color: 'rgb(158, 161, 176)'
      },
      itemHoverStyle: {
        // color: hoverColor()
      }
    },
    title: {
      text: 'Cost Break Down',
      align: 'center',
      verticalAlign: 'top',
      y: 40,
      style: {
        color: 'rgb(158, 161, 176)'
      }
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          // distance: 0,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true,
        // startAngle: -90,
        // endAngle: 90,
        // center: ['50%', '100%'],
        size: '80%'
      },
      series: {
        borderWidth: 1,
        // borderColor: blockColor()
      }
    },
    series: [{
      name: 'Cost',
      colorByPoint: true,
      innerSize: '50%',
      data: data
    }]
  }

  const makeData = () => {

    let categorySum = new Map();

    transactions.forEach(transaction => {
      if (transaction.isIncome) return;
      let name = transaction.category;
      if (categorySum.get(name)) {
        categorySum.set(name, categorySum.get(name) + transaction.amount);
      } else {
        categorySum.set(name, transaction.amount);
      }
    });

    let tempData = [] as PieData[];

    categorySum.forEach((value, key) => {
      tempData.push({
        name: key,
        y: value
      })
    });

    setData(tempData);
  }

  return (
    <Paper>
      <PieChart highcharts={Highcharts} options={options} />
    </Paper>
  )
}

export default CostPieChart
