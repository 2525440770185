import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


import { Estate, Transaction } from '../API';
import { CATEGORIES } from '../category';
import { Container } from '@mui/material';

interface ReportProps {
    transactions: Transaction[],
    estatesState: {
      estates: Estate[], 
      imageSrc: string[],
      selectedIndex: number
    },
    longTermCost: {
      borrowingExpense: number,
      fiveYearsAssets: number,
      severYearsAssets: number,
      capitalWorks: number
    },
    dateRange: string[],
    returnHandler: () => void
}

function Report({ 
    transactions, 
    estatesState, 
    longTermCost, 
    dateRange, 
    returnHandler 
  }: ReportProps) {

  let title = 'All Estates';

  let income = 0, cost = 0;
  let categorySum: Map<string, number> = new Map();

  const calculateCategorySum = () => {
    let trans: Transaction[] = transactions;

    if (estatesState.selectedIndex >= 0) {
      trans = transactions.filter(t => t.estateID === estatesState.estates[estatesState.selectedIndex].id);
      title = estatesState.estates[estatesState.selectedIndex].name;
    }  

    categorySum = new Map();

    for (const transaction of trans) {
      let name = transaction.category;
      if (name === 'Cost Base' ||
          name === 'Borrowing Base' ||
          name === 'Capital Allowance' ||
          name === 'Capital Works' ||
          name === 'Principal Payment' ||
          name === 'Depreciating Assets - 5 Years' ||
          name === 'Depreciating Assets - 7 Years')
          continue;
      
      if (categorySum && categorySum.get(name)) {
        let pa = categorySum.get(name)?? 0;
        categorySum.set(name, pa + transaction.amount);
      } else {
        categorySum.set(name, transaction.amount);
      }
      if (transaction.isIncome) income += transaction.amount;
      else if (transaction.category !== 'Principal Payment') cost += transaction.amount;
    }

    cost += longTermCost.borrowingExpense + 
            longTermCost.fiveYearsAssets + 
            longTermCost.severYearsAssets +
            longTermCost.capitalWorks;
  }

  calculateCategorySum();

  const getValue = (name: string) => {
    let amount: number;

    if (name === 'Borrowing Expense') {
      amount = longTermCost.borrowingExpense;
    } else if (name === 'Capital Allowance') {
      amount = longTermCost.fiveYearsAssets + longTermCost.severYearsAssets;
    } else if (name === 'Capital Works') {
      amount = longTermCost.capitalWorks;
    } else {
      amount = categorySum.get(name)?? 0;
    }
    
    if (amount && amount !== 0) {
      return amount.toLocaleString('en-US', { 
        style: 'currency', 
        currency: 'USD'
      });
    }
    else {
      return 'NaN';
    }
  }

  const generateSum = (s: number, e: number) => {
    return CATEGORIES.slice(s,e).map( c => {
      return (
        <TextField
          key={c.name}
          disabled
          fullWidth
          label={`Total ${c.name}`}
          defaultValue={getValue(c.name)}
          inputProps={{style: { textAlign: 'right' }}}
          margin="dense"
        />
      );}
    )
  } 

  return (
    <Container maxWidth="sm">
      <Paper sx={{ width: '100%', padding: 2 }}>
      <Typography variant="body1" color="text.secondary">
        Tax Report for {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        From {dateRange[0].slice(0,10)} to {dateRange[1].slice(0,10)}
      </Typography>
      <hr/>
      <Typography variant="h6" color="text.secondary">
        Rental Income
      </Typography>
      {generateSum(2, 4)}
      <Typography variant="h6" color="text.secondary">
        Total Gross Rent: {income.toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD'
        })}
      </Typography>
      <hr/>
      <Typography variant="h6" color="text.secondary">
        Rental Expense
      </Typography>
      {generateSum(4, 23)}
      <Typography variant="h6" color="text.secondary">
        Total Expense: {cost.toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD'
        })}
      </Typography>
      <hr/>
      <Typography variant="h6" color="text.secondary">
        Net Rent: {(income - cost).toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD'
        })}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        * Borrowing expenses are spread to 5 years.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        * Capital Allowances are spread to 5 or 7 years for depreciating assets.
      </Typography>
      <Typography variant="body2" color="text.secondary">
        * Capital Works are spread to 40 years for house related works.
      </Typography>


      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          // variant="contained" 
          style={{ marginLeft: "auto" }}
          onClick={returnHandler}
        >
          Close
        </Button>
      </Box>
      </Paper>
    </Container>
  )
}

export default Report
