import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';

import { visuallyHidden } from '@mui/utils';

import { Estate, Transaction,  } from '../../API'
import { Data, getComparator, Order } from './sort';
import EnhancedTableToolbar, { Filter } from './EnhancedTableToolbar';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { deleteTransaction } from '../../graphql/mutations';

interface TransactionListProps {
    estate: Estate | Estate[],
    transactions: Transaction[],
    homeSwitch: (name: string, params: any[]) => void
}

function TransactionList({ estate, transactions, homeSwitch }: TransactionListProps) {
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [rows, setRows] = useState(transactions as Data[]);
  useEffect(() => {
    setRows(transactions as Data[]);
  },[transactions]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDeleteTransactionID, setToDeleteTransactionID] = useState('');

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const createSortHandler =
  (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClickedHandler = (id: string) => {
    let transaction = transactions.find(t => t.id === id);
    homeSwitch('UpdateTransactionClicked', [transaction]);
  }

  const deleteButtonClickedHandler = (id: string) => {
    setToDeleteTransactionID(id);
    setConfirmOpen(true);
  }

  const deleteConfirmHandler = async () => {
    try{
      if (toDeleteTransactionID === '') return;
      await API.graphql(
        graphqlOperation(
          deleteTransaction,
          {
            input: {
              id: toDeleteTransactionID, 
            }
          }
        )
      );

      let newRows = [ ...rows ];
      let index = newRows.findIndex(r => r.id === toDeleteTransactionID);
      let deletedTransactions = newRows.splice(index, 1);

      // Remove attached files
      let files: [string] = JSON.parse(deletedTransactions[0].fileKey?? '[]');
      files.forEach(file => {
        Storage.remove(file, { level: 'private' });
      });

      setRows(newRows);

      setConfirmOpen(false);
      homeSwitch('SuccessNotification', ['Transaction has been deleted successfully!']);
      homeSwitch('TransactionDeleted', [toDeleteTransactionID]);

    } catch (err) {
      console.error(err);
      homeSwitch('ErrorNotification', [`Failed to delete the transaction. ${err}`]);
    }
  }

  const filterList = (filter: Filter) => {
    if (filter === 'Income') {
      setRows(transactions.filter(t => t.isIncome) as Data[]);
    } else if (filter === 'Expense') {
      setRows(transactions.filter(t => !t.isIncome) as Data[]);
    } else {
      setRows(transactions as Data[]);
    }
    
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar  
            estate={estate}
            homeSwitch={homeSwitch}
            filterList={filterList}
          />
          <Box display={{ xs: 'none', md: 'block' }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 200 }}
                aria-labelledby="tableTitle"
                size='small'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.minWidth, maxWidth: headCell.maxWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                      <TableCell align='left' padding='normal'>
                        Description
                      </TableCell>
                      <TableCell />

                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={() => rowClickedHandler(row.id)}
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          // sx={{cursor: "pointer"}}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.category}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {
                              row.isIncome ? "+" : "-"
                            } {
                              row.amount.toLocaleString(
                                'en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }
                              )
                            }
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer"
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            {row.date.slice(0,10)}
                          </TableCell>
                          <TableCell 
                            sx={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              cursor: "pointer",
                            }}
                            onClick={() => rowClickedHandler(row.id)}
                          >
                            { row.description && row.description.length > 50 ? 
                              row.description.slice(0,50) : 
                              row.description }
                          </TableCell>
                          <TableCell
                            sx={{
                              width: "24px",
                              padding: 0,
                            }}
                          >
                            <IconButton
                              onClick={() => deleteButtonClickedHandler(row.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box display={{ sm: 'block', md: 'none' }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 200 }}
                aria-labelledby="tableTitle"
                size='small'
              >
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ minWidth: headCell.minWidth, maxWidth: headCell.maxWidth }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={() => rowClickedHandler(row.id)}
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          sx={{cursor: "pointer"}}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.category}
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {
                              row.isIncome ? "+" : "-"
                            } {
                              row.amount.toLocaleString(
                                'en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }
                              )
                            }
                          </TableCell>
                          <TableCell align="right">{row.date.slice(5,10)}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="modal-title">Please confirm</h2>
          <p id="modal-description">
            All files associated to this transaction will be deleted permanently from cloud, are you sure to delete it?
          </p>
          <Button onClick={deleteConfirmHandler}>Yes</Button>
          <Button onClick={()=>setConfirmOpen(false)}>No</Button>
        </Box>
      </Modal>

    </>
  )
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  minWidth: number;
  maxWidth: number;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
    minWidth: 30,
    maxWidth: 50
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
    minWidth: 30,
    maxWidth: 50
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Date',
    minWidth: 30,
    maxWidth: 50
  },
];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default TransactionList
