import { useRef, useState } from 'react'
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';



interface DateRangePickerCardProps {
  dateRange: string[]
  homeSwitch: (name: string, params: any[]) => void,
  cancelHandler: () => void
}

function DateRangePickerCard({ dateRange, homeSwitch, cancelHandler }: DateRangePickerCardProps) {
  const sd = useRef(new Date(dateRange[0].slice(0,10)));
  const ed = useRef(new Date(dateRange[1].slice(0,10)));
  const [startDate, setStartDate] = useState(sd.current);
  const [endDate, setEndDate] = useState(ed.current);
  const type = useRef('');
  const quickOptions = [
    'This Month',
    'Last 3 Months',
    'This Calendar Year',
    'Last Calendar Year',
  ]

  let year = new Date().getFullYear();
  let month = new Date().getMonth();

  if (month < 6) year -= 1;
  
  const lastFiveFinancialYears: string[][] = [];
  for (let i = 0; i < 5; i++) {
    lastFiveFinancialYears.push([
      `${year - 1}-07-01+10:00`,
      `${year}-06-30+10:00`
    ])
    year -= 1;
  }

  const dateChangedHandler = (date: Date | null, isStartDate: boolean) => {
    if (date){
      if (isStartDate) {
        setStartDate(date);
        sd.current = date;
      } 
      else {
        setEndDate(date);
        ed.current = date;
      } 
    }
    type.current='Date Range';
  }

  const quickSetDateRange = async (t: string) => {
    let date = new Date(),
        tsd = new Date(),
        ted = new Date()
    
    switch (t) {
      case 'This Month':
        ted = new Date(date);
        date.setDate(1);
        tsd = date;
        break;
      case 'Last 3 Months':
        ted = new Date(date);
        date.setMonth(date.getMonth() - 3);
        date.setDate(1);
        tsd = date;
        break;
      case 'This Calendar Year':
        ted = new Date(date);
        date.setMonth(0);
        date.setDate(1);
        tsd = date;
        break;
      case 'This Financial Year':
        ted = new Date(date);
        if (date.getMonth() < 6) {
          date.setFullYear(date.getFullYear() - 1);
        }
        date.setMonth(6);
        date.setDate(1);
        tsd = date;
        break;
      case 'Last Calendar Year':
        date.setFullYear(date.getFullYear() - 1);
        date.setMonth(11);
        date.setDate(31);
        ted = new Date(date);
        date.setMonth(0);
        date.setDate(1);
        tsd = date;
        break;
      case 'Last Financial Year':
        date.setMonth(5);
        date.setDate(30);
        ted = new Date(date);
        date.setMonth(6);
        date.setDate(1);
        tsd = date;
        let year = date.getFullYear();
        if (date.getMonth() < 6) {
          ted.setFullYear(year - 1);
          tsd.setFullYear(year - 2);
        } else {
          tsd.setFullYear(year - 1);
        }
        break;
    }

    setStartDate(tsd);
    setEndDate(ted);
    type.current = t;
    sd.current = tsd;
    ed.current = ted;

    okClickHandler();
  }

  const quickSetFinancialYear = (year: string[]) => {
    type.current = `F${year[0].slice(0,4)}/${year[1].slice(0,4)}`;
    homeSwitch('DateRangeOkClicked', year.concat(type.current));
  }

  const okClickHandler = () => {
    homeSwitch('DateRangeOkClicked', [
      `${sd.current.toJSON().slice(0,10)}+10:00`,
      `${ed.current.toJSON().slice(0,10)}+10:00`,
      type.current
    ]);
  }


  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          {quickOptions.map((option,i)=>(
            <Button
              key={option}
              onClick={()=>quickSetDateRange(option)}>
              {option}
            </Button>
          ))}
          <Typography variant="body1" color="text.secondary">
            Financial Year
          </Typography>
          <div>
            <Button onClick={()=>quickSetDateRange('This Financial Year')}>
              This Financial Year
            </Button>
          </div>
          {lastFiveFinancialYears.map((year,i)=>(
            <Button
              key={i.toString()}
              onClick={()=>quickSetFinancialYear(year)}>
              {year[0].slice(0,4)} - {year[1].slice(0,4)}
            </Button>
          ))}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Start Date"
              value={startDate}
              inputFormat="yyyy-MM-dd"
              onChange={(newValue) => {
                dateChangedHandler(newValue, true);
              }}
              renderInput={(params) => <TextField {...params} fullWidth  sx={{ m: 1 }} variant="standard"/>}
            />
            <MobileDatePicker
              label="End Date"
              value={endDate}
              inputFormat="yyyy-MM-dd"
              onChange={(newValue) => {
                dateChangedHandler(newValue, false);
              }}
              renderInput={(params) => <TextField {...params} fullWidth  sx={{ m: 1 }} variant="standard"/>}
            />
          </LocalizationProvider>
        </CardContent>
        <CardActions>
            <Button 
              variant="contained" 
              style={rightAlignItem}
              onClick={okClickHandler}
            >
              OK
            </Button>
            <Button 
              variant="contained" 
              color="error"
              onClick={cancelHandler}
            >
              Cancel
            </Button>
          </CardActions>
      </Card>
    </Container>
  );
}

const rightAlignItem = {
  marginLeft: "auto"
}

export default DateRangePickerCard
