/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEstate = /* GraphQL */ `
  query GetEstate($id: ID!) {
    getEstate(id: $id) {
      id
      name
      address
      imageKey
      Transactions {
        items {
          id
          date
          amount
          description
          category
          isIncome
          fileKey
          estateID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEstates = /* GraphQL */ `
  query ListEstates(
    $filter: ModelEstateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEstates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        imageKey
        Transactions {
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      date
      amount
      description
      category
      isIncome
      fileKey
      estateID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        amount
        description
        category
        isIncome
        fileKey
        estateID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const transactionsByCategoryAndDate = /* GraphQL */ `
  query TransactionsByCategoryAndDate(
    $category: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByCategoryAndDate(
      category: $category
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        amount
        description
        category
        isIncome
        fileKey
        estateID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
