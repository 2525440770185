import { useState } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { deleteEstate } from '../graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';


import { Estate, ListTransactionsQuery, Transaction } from '../API';
import { listTransactions } from '../graphql/queries';
import { deleteTransactionAndFile } from '../transactions';

interface EstateCardsProps {
  estatesState: {
    estates: Estate[];
    imageSrc: string[];
    selectedIndex: number
  } | undefined;
  setEstatesState: React.Dispatch<React.SetStateAction<{
    estates: Estate[];
    imageSrc: string[];
    selectedIndex: number
  }>>;
  homeSwitch: (name: string, params: any[]) => void
}

function EstateCards({ estatesState, setEstatesState, homeSwitch }: EstateCardsProps) {

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [toDeleteEstate, setToDeleteEstate] = useState<Estate>();
  const [progress, setProgress] = useState(0);

  const updateClickHandler = (estate?: Estate) => {
    if (estate && estatesState){
      let index = estatesState?.estates.indexOf(estate);
      homeSwitch('UpdateEstateClicked', [index]);
    }
  }

  const addNewClickHandler = () => homeSwitch('AddNewEstateClicked', []);

  const deleteClickHandler = (estate: Estate) => {
    setDeleteConfirmOpen(true);
    setToDeleteEstate(estate);
  }

  const deleteConfirmHandler = async () => {
    try{
      if (!toDeleteEstate) return;

      let result = await API.graphql(graphqlOperation(
        listTransactions, {
          filter: {
            estateID: { eq: toDeleteEstate.id }
          }
        }
      )) as GraphQLResult<ListTransactionsQuery>

      setProgress(10);
      let trans = result.data?.listTransactions?.items as Transaction[]

      for (const [i, transaction] of trans.entries()) {
        let result = await deleteTransactionAndFile(transaction);
        if (!result.succeeded) {
          throw (result.message);
        }
        setProgress(10 + 85 * (i + 1)/ trans.length);
      }

      await API.graphql(
        graphqlOperation(
          deleteEstate,
          {
            input: {
              id: toDeleteEstate.id, 
            }
          }
        )
      );
      
      setProgress(99);
      await Storage.remove(toDeleteEstate.imageKey, {level: 'private'});

      setDeleteConfirmOpen(false);
      homeSwitch('SuccessNotification', ['Estate has been deleted successfully!']);

      if (estatesState) {
        let index = estatesState.estates.indexOf(toDeleteEstate);
        estatesState.estates.splice(index, 1);
        estatesState.imageSrc.splice(index, 1);
      }
    } catch (err) {
      console.log(err);
      homeSwitch('ErrorNotification', [`Failed to delete the estate. ${err}`]);
    }
  }

  const cardClickedHandler = (index: number) => {
    homeSwitch('CardClicked', [index]);
  }

  const imageErrorHandler = async () => {
    if (!estatesState) return;

    const images: string[] = [];

    for (let i = 0; i < estatesState.estates.length; i++) {
      const estate = estatesState.estates[i];

      if (estate.imageKey === 'default'){
        images[i] = `${process.env.PUBLIC_URL}/images/defaultImage.png`;
      } else {
        images[i] = await Storage.get(estate.imageKey, { level: 'private'});
      }
    }

    setEstatesState({...estatesState, imageSrc: images});
  }

  return (
    <>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}
        >
          {estatesState?.estates.map((estate, index) => {
            return (
                <Card key={estate.id ? estate.id : index}
                  sx={{
                    width: 300,
                    margin: 2
                  }}
                >
                  <Tooltip 
                    title="Go to dashboard" 
                    placement="top" 
                    followCursor 
                    style={imageWrapper}
                    onClick={() => cardClickedHandler(index)}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={estatesState.imageSrc[index]}
                      alt="my estate"
                      onError={imageErrorHandler}
                    />
                  </Tooltip>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {estate.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {estate.address}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button 
                      size="small" 
                      onClick={() => updateClickHandler(estate)}
                    >
                      Update
                    </Button>
                    <Button 
                      size="small" 
                      onClick={() => deleteClickHandler(estate)}
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
            )})}
          <Card 
            sx={{
              width: 300,
              margin: 2
            }}
          >
            <Tooltip 
              title="Add an estate" 
              placement="top" 
              followCursor 
              style={imageWrapper}
            >
              <CardMedia
                component="img"
                height="200"
                image={`${process.env.PUBLIC_URL}/images/defaultImage.png`}
                alt="my estate"
                onClick={addNewClickHandler}
              />
            </Tooltip>
          </Card>
        </Box>
        <Modal
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 id="modal-title">Please confirm</h2>
            <p id="modal-description">
              Are you sure you want to delete the estate? All transactions and attached files will be deleted!
            </p>
            <Button onClick={deleteConfirmHandler}>Yes</Button>
            <Button onClick={()=>setDeleteConfirmOpen(false)}>No</Button>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        </Modal>
      </Container>
    </>
  )
}

const imageWrapper = {
    cursor: "pointer"
  }

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default EstateCards
