import { useEffect, useMemo, useState } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { GraphQLResult } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { listEstates, listTransactions, transactionsByCategoryAndDate } from './graphql/queries';
import { Estate, ListEstatesQuery, Transaction, ListTransactionsQuery, TransactionsByCategoryAndDateQuery } from './API';
import Highcharts from "highcharts/highstock";

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Header from './components/Header';
import EstateForm from './components/EstateForm';
import EstateCards from './components/EstateCards';
import TransactionForm from './components/TransactionForm';
import Dashboard from './components/Dashboard';
import TransactionList from './components/TransactionList/TransactionList';
import DateRangePickerCard from './components/DateRangePickerCard';
import Report from './components/Report';
import { CssBaseline, useMediaQuery } from '@mui/material';


function App() {
  // Enabling Dark Mode according to system-wide setting
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () => createTheme({
      palette: {
        mode: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  useMemo(() => {
    Highcharts.theme = prefersDarkMode ? {
      chart: {
        backgroundColor: {
          stops: [
              [0, 'rgba(255, 255, 255, 0.05)'],
              [1, 'rgba(255, 255, 255, 0.05)']
          ]
        }
      }
    } : {
      chart: {
        backgroundColor: {
          stops: [
              [0, 'rgba(255, 255, 255, 0.05)'],
              [1, 'rgba(255, 255, 255, 0.05)']
          ]
        }
      }
    }

    Highcharts.setOptions(Highcharts.theme);
  }, [prefersDarkMode])

  const initEstate: Estate = {
    name: '',
    address: '',
    imageKey: 'default',
  };

  const [estateFormProps, setEstateFormProps] = useState<{
    initEstate: Estate,
    image: string | undefined
  }>({
    initEstate: initEstate,
    image: undefined
  });

  const [estatesState, setEstatesState] = useState<{
    estates: Estate[], 
    imageSrc: string[],
    selectedIndex: number
  }>({
    estates: [],
    imageSrc: [],
    selectedIndex: -1
  });

  type ShowState = {
    showEstateCards: boolean,
    showEstateForm: boolean,
    showTransactionForm: boolean,
    showDashboard: boolean,
    showReport: boolean,
    showTransactions: boolean,
    showDateRangePicker: boolean
  }

  const closeAllState: ShowState = {
    showEstateCards: false,
    showEstateForm: false,
    showTransactionForm: false,
    showDashboard: false,
    showReport: false,
    showTransactions: false,
    showDateRangePicker: false,
  }

  const [showState, setShowState] = useState<ShowState>({
    ...closeAllState,
    showEstateCards: true
  });

  const [showStateHistory] = useState<ShowState[]>([]);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const [transactionFormProps, setTransactionFormProps] = useState<Transaction>(
    transactions[0]
  );

  const [dashboardProps, setDashboardProps] = useState<{
    estate: Estate,
    transactions: Transaction[],
  }>({
    estate: estatesState.estates[0],
    transactions: []
  })

  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [longTermCost, setLongTermCost] = useState({
    borrowingExpense: 0,
    fiveYearsAssets: 0,
    severYearsAssets: 0,
    capitalWorks: 0
  });
  const [progress, setProgress] = useState(0);

  let date = new Date();
  date.setDate(date.getDate() + 1);
  let ed = date.toJSON().slice(0,10) + '+10:00';
  date.setMonth(date.getMonth() - 3);
  date.setDate(1);
  let sd = date.toJSON().slice(0,10) + '+10:00';

  const [dateRange, setDateRange] = useState([sd, ed]);
  const [dateTitle, setDateTile] = useState('Last 3 Months');

  const [username, setUsername] = useState('');
  
  useEffect(() => {
    fetchEstates();
    fetchTransactions();
    Auth.currentAuthenticatedUser().then(user => {
      setUsername(user.attributes.name);
    })
  },[]);

  useEffect(() => {
    if (estatesState.selectedIndex >= 0) {
      makeDashboardProps(estatesState.selectedIndex);
    }
  }, [transactions])

  const homeSwitch = (name:string, params:any[]) => {
    switch (name) {
      case 'SuccessNotification':
        successNotificationHandler(params[0]);
        break;
      case 'ErrorNotification':
        errorNotificationHandler(params[0]);
        break;
      case 'MenuHomeClicked':
        menuHomeClickedHandler();
        break;
      case 'MenuTransactionsClicked':
        menuTransactionsClicked();
        break;
      case 'MenuReportClicked':
        menuReportClicked();
        break;
      case 'SetDateClicked':
        setDateClickedHandler();
        break;
      case 'DateRangeOkClicked':
        dateRangeOkClickedHandler(params[0], params[1], params[2]);
        break;
      case 'AddNewEstateClicked':
        addNewEstateClickedHandler();
        break;
      case 'EstateFormCancelClicked':
        estateFormCancelClickedHandler();
        break;
      case 'UpdateEstateClicked':
        updateEstateClickedHandler(params[0]);
        break;
      case 'NewEstateAdded':
        newEstateAddedHandler(params[0].estate, params[0].image);
        break;
      case 'EstateUpdated':
        estateUpdatedHandler(params[0]);
        break;
      case 'CardClicked':
        cardClickedHandler(params[0]);
        break;
      case 'AddNewTransactionClicked':
        addNewTransactionClickedHandler(params[0]);
        break;
      case 'NewTransactionAdded':
        newTransactionAddedHandler(params[0]);
        break;
      case 'TransactionUpdated':
        transactionUpdatedHandler(params[0]);
        break;
      case 'UpdateTransactionClicked':
        updateTransactionClickedHandler(params[0]);
        break;
      case 'TransactionDeleted':
        transactionDeletedHandler(params[0]);
        break;
      case 'TransactionsImported':
        transactionsImportedHandler(params);
        break;
    } 
  }

  const setDateClickedHandler = () => {
    showStateHistory.push(showState);
    setShowState({
      ...closeAllState,
      showDateRangePicker: true
    });
  }

  const fetchEstates = async () => {
    try {
      const estatesData = await API.graphql(graphqlOperation(listEstates)) as GraphQLResult<ListEstatesQuery>;
      const estates = estatesData.data?.listEstates?.items as Estate[];
      
      const images: string[] = [];
      for (let i=0; i<estates.length; i++) {
        if (estates[i].imageKey === 'default'){
          images[i] = `${process.env.PUBLIC_URL}/images/defaultImage.png`;
        } else {
          images[i] = await Storage.get(estates[i].imageKey, { level: 'private'});
        }
      }
  
      setEstatesState({
        ...estatesState,
        estates: estates,
        imageSrc: images,
      });
    } catch (err) {
      console.error(err);
    }
  }

  const fetchTransactions = async (sd?: string, ed?: string) => {
    if (!ed) {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      ed = date.toJSON().slice(0,10) + '+10:00';
    }

    if (!sd) {
      let date = new Date();
      date.setMonth(date.getMonth() - 3);
      date.setDate(1);
      sd = date.toJSON().slice(0,10) + '+10:00';
    }

    try {
      let nextToken: string | null | undefined = null;
      let trans: Transaction[] = [];

      do {
        const result = await API.graphql(
          graphqlOperation(
            listTransactions, 
            {
              filter: { date: { between: [sd, ed] }},
              nextToken: nextToken 
            }, 
          )
        ) as GraphQLResult<ListTransactionsQuery>;

        trans = trans.concat(result.data?.listTransactions?.items as Transaction[]);
        nextToken = result.data?.listTransactions?.nextToken;
      } while (nextToken)
        
      setTransactions(trans);

    } catch (err) {
      console.error(err);
    }
  }

  const successNotificationHandler = (msg: string) => {
    setSuccessMessage(msg);
    setSuccessOpen(true);
    setTimeout(() => setSuccessOpen(false), 3000);
  }

  const errorNotificationHandler = (msg: string) => {
    setErrorMessage(msg);
    setErrorOpen(true);
  }

  const menuHomeClickedHandler = () => {
    setEstatesState({
      ...estatesState,
      selectedIndex: -1
    })
    setShowState({
      ...closeAllState,
      showEstateCards: true
    });
  }

  const menuTransactionsClicked = () => {
    setShowState({
      ...closeAllState,
      showTransactions: true,
    });
  }

  const menuReportClicked = async () => {
    await getLongTermCost(dateRange[1]);
    showStateHistory.push(showState);
    setShowState({
      ...closeAllState,
      showReport: true
    })
  }

  const addNewEstateClickedHandler = () => {
    setEstateFormProps({
      initEstate: initEstate,
      image: undefined
    });
    setShowState({
      ...closeAllState,
      showEstateForm: true,
    });
  }

  const estateFormCancelClickedHandler = () => {
    setShowState({
      ...closeAllState,
      showEstateCards: true,
    });
  }

  const updateEstateClickedHandler = (index: number) => {
    setEstateFormProps({
      initEstate: estatesState.estates[index],
      image: estatesState.imageSrc[index]
    });
    setShowState({
      ...closeAllState,
      showEstateForm: true,
    });
  }

  const newEstateAddedHandler = (estate: Estate, image: string) => {
    estatesState.estates.push(estate);
    estatesState.imageSrc.push(image);
    setShowState({
      ...closeAllState,
      showEstateCards: true,
    })
  }

  const estateUpdatedHandler = (estate: Estate) => {
    for (let index = 0; index < estatesState.estates.length; index++) {
      if (estate.id === estatesState.estates[index].id) 
      estatesState.estates[index] = estate;
    }
    setShowState({
      ...closeAllState,
      showEstateCards: true,
    })
  }

  const addNewTransactionClickedHandler = (estate: Estate) => {
    if (!estate.id) return;

    let initTransaction: Transaction = {
      estateID: estate.id,
      date: '',
      category: '',
      isIncome: false,
      amount: 0
    }

    setTransactionFormProps(initTransaction);

    showStateHistory.push(showState);

    setShowState({
      ...closeAllState,
      showTransactionForm: true,
    })
  }

  // TODO
  const makeDashboardProps = (index: number) => {
    if (estatesState.estates.length === 0) return false;
    const estate = estatesState.estates[index];
    const trans = transactions.filter(t => t.estateID === estate.id);

    if (trans) {
      setDashboardProps({
        estate: estate,
        transactions: trans,
      });
      return true;
    } else {
      return false;
    }
  }

  const cardClickedHandler = async (index: number) => {
    setEstatesState({ ...estatesState, selectedIndex : index });
    if (makeDashboardProps(index)) {
      setShowState({
        ...closeAllState,
        showDashboard: true,
      })
    } else {
      successNotificationHandler('No transactions in this estate.');
    }
  }

  const newTransactionAddedHandler = (newTransaction: Transaction) => {
    if (newTransaction.date >= dateRange[0] && newTransaction.date <= dateRange[1]) {
      let trans = [ ...transactions, newTransaction ];
      setTransactions(trans);
    }
  }

  const updateTransactionClickedHandler = (transaction: Transaction) => {
    showStateHistory.push({ ...showState });

    setTransactionFormProps(transaction);
    setShowState({
      ...closeAllState,
      showTransactionForm: true
    })
  }

  const transactionUpdatedHandler = (updatedTransaction: Transaction) => {
    if (!transactions) return;

    let index = transactions.findIndex(t => t.id === updatedTransaction.id);
    let trans = [ ...transactions ];
    trans[index] = updatedTransaction;
    setTransactions(trans);

    let state = showStateHistory.pop();

    setTimeout(() => {
      if (state) {
        setShowState(state);
      } else {
        setShowState({
          ...closeAllState,
          showEstateCards: true
        })
      }
    }, 1000)
    
  }

  const transactionDeletedHandler = (id: string) => {
    let trans = [ ...transactions ];
    let index = trans.findIndex(t => t.id === id);
    trans.splice(index, 1);
    setTransactions(trans);
  }

  const transactionsImportedHandler = (newTransactions: Transaction[]) => {
    let filteredTrans = newTransactions.filter(t => t.date >= dateRange[0] && t.date <= dateRange[1]);
    let trans = transactions.concat(filteredTrans);
    setTransactions(trans);
    successNotificationHandler('Transactions have been imported successfully.')
  }

  const dateRangeOkClickedHandler = async (sd: string, ed: string, type: string) => {
    setDateTile(type);
    setDateRange([sd, ed]);

    await fetchTransactions(sd, ed);

    let state = showStateHistory.pop();

    if (state && state.showReport) {
      await getLongTermCost(ed);
    }

    if (state) {
      setShowState(state);
    } else {
      setShowState({
        ...closeAllState,
        showEstateCards: true
      });
    }
  }

  const showPreviews = async () => {
    let state = showStateHistory.pop();

    if (state) {
      setShowState(state);
    } else {
      setShowState({
        ...closeAllState,
        showEstateCards: true
      });
    }
  }

  const getCostByCategoryAndYears = async (category: string, number: number, year: string) => {
    try {
      let ed = new Date(year.slice(0,10));
      let sd = new Date(ed);
      sd.setFullYear(ed.getFullYear() - number);
  
      let nextToken: string | null | undefined = null;
      let trans: Transaction[] = [];
  
      do {
        let result = await API.graphql(
          graphqlOperation(
            transactionsByCategoryAndDate, 
            {
              category: category,
              date: { between: [sd.toJSON().slice(0,10)+'+10:00', ed.toJSON().slice(0,10)+'10:00'] },
              limit: 100,
              nextToken: nextToken
            }, 
          )
        ) as GraphQLResult<TransactionsByCategoryAndDateQuery>;

        trans = trans.concat(result.data?.transactionsByCategoryAndDate?.items as Transaction[]);
        nextToken = result.data?.transactionsByCategoryAndDate?.nextToken;

      } while (nextToken)
  
      if (estatesState.selectedIndex >= 0) {
        trans = trans.filter(t => t.estateID === estatesState.estates[estatesState.selectedIndex].id);
      }
  
      let sum = trans.reduce(
        (acc, t) => acc + t.amount, 0 
      )
  
      return sum;

    } catch (err) {
      console.error(err);
      return 0;
    }
  }

  const getLongTermCost = async (ed: string) => {
    setProgress(10);
    let borrowingExpense = await getCostByCategoryAndYears('Borrowing Base', 5, ed);
    setProgress(40);
    let fiveYearAssets = await getCostByCategoryAndYears('Depreciating Assets - 5 Years', 5, ed);
    setProgress(70);
    let sevenYearAssets = await getCostByCategoryAndYears('Depreciating Assets - 7 Years', 7, ed);
    setProgress(90);
    let capitalWorks = await getCostByCategoryAndYears('Capital Works', 40, ed);
    setProgress(100);

    setLongTermCost({
      borrowingExpense: borrowingExpense/5,
      fiveYearsAssets: fiveYearAssets/5,
      severYearsAssets: sevenYearAssets/7,
      capitalWorks: capitalWorks/40,
    })

    setTimeout(() => {
      setProgress(0);
    }, 500)
  }
  

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header 
        setDate={dateTitle}
        username={username}
        homeSwitch={homeSwitch}
      />
      <LinearProgress variant="determinate" value={progress} />
      <div style={marginTop}>
        <Collapse in={successOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() =>  setSuccessOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {successMessage}
          </Alert>
        </Collapse>
        <Collapse in={errorOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setErrorOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Collapse>
        <Container maxWidth="md">
          {showState.showEstateCards &&
            <EstateCards 
              estatesState={estatesState}
              setEstatesState={setEstatesState}
              homeSwitch={homeSwitch}
            />
          }
          {showState.showEstateForm &&
            <EstateForm
              initEstate={estateFormProps.initEstate}
              image={estateFormProps.image}
              homeSwitch={homeSwitch}
            />
          }
          {showState.showTransactionForm &&
            <TransactionForm
              estates={estatesState.estates}
              initTransaction={transactionFormProps}
              homeSwitch={homeSwitch}
              cancelHandler={showPreviews}
            />
          }
          {showState.showDashboard &&
            <Dashboard
              estate={dashboardProps?.estate}
              transactions={dashboardProps?.transactions}
              dateRange={dateRange}
              homeSwitch={homeSwitch}
            />
          }
          {showState.showTransactions &&
            <TransactionList
              estate={estatesState.estates}
              transactions={transactions}
              homeSwitch={homeSwitch}
            />
          }
          {showState.showDateRangePicker &&
            <DateRangePickerCard 
              dateRange={dateRange}
              homeSwitch={homeSwitch}
              cancelHandler={showPreviews}
            />
          }
          {showState.showReport &&
            <Report 
              transactions={transactions}
              estatesState={estatesState}
              longTermCost={longTermCost}
              dateRange={dateRange}
              returnHandler={showPreviews}
            />
          }
        </Container>
      </div>
    </ThemeProvider>
  );
}

const marginTop = {
  marginTop: '20px'
}

export default withAuthenticator(App);
