import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { Estate, Transaction } from '../API'
import CostPieChart from './CostPieChart';
import TransactionList from './TransactionList/TransactionList'
import TransactionsBarChart from './TransactionsBarChart';

interface DashboardProps {
    estate: Estate,
    transactions: Transaction[],
    dateRange: string[],
    homeSwitch: (name: string, params: any[]) => void
}

function Dashboard({ estate, transactions, dateRange, homeSwitch}: DashboardProps) {

  return (
    // <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TransactionsBarChart 
            transactions={transactions}
            dateRange={dateRange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CostPieChart transactions={transactions}/>
        </Grid>
        <Grid item xs={12}>
          <TransactionList
            estate={estate}
            transactions={transactions}
            homeSwitch={homeSwitch}
          />
        </Grid>
      </Grid>
    // </Container>
  )
}

export default Dashboard